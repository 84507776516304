.logo {
    width: 240px;
    height: 240px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.center {
    text-align: center;
}